<template>
  <div class="create-batch-check-order-job">
    <breadcrumb style="margin-left: 15px"></breadcrumb>
    <div class="content flex-ct between" style="min-height: 0">
      <a-tabs default-active-key="invoiceInfo" @change="changeTabs">
        <a-tab-pane key="invoiceInfo" :tab="$t(`发票信息`)"></a-tab-pane>
        <a-tab-pane key="sellerInfo" :tab="$t(`开票人信息`)"></a-tab-pane>
        <a-tab-pane key="buyerInfo" :tab="$t(`购买方信息`)"></a-tab-pane>
        <a-tab-pane key="ProductInfo" :tab="$t(`商品信息`)"></a-tab-pane>
        <a-tab-pane key="PayableListInfo" :tab="$t(`关联订单信息`)"></a-tab-pane>
      </a-tabs>
      <div class="btns flex-ct">
        <a-button
          type="primary"
          v-if="['PART_WRITTEN_OFF', 'WAIT_WRITTEN_OFF'].includes(formData.status)"
          @click="bindOrderHandle"
        >
          {{ $t('关联应付订单') }}
        </a-button>
        <OperateBtn
          class="ml10"
          v-if="formData.status === 'WAIT_CHECK'"
          api="checkInvoice"
          :text="$t('审核')"
          :params="{
            invoiceIdList: [id],
          }"
          @success="init"
        />
      </div>
    </div>
    <div class="content-overflow">
      <section class="left-div fixContent" :style="{ height: scrollHeight }">
        <a-form-model ref="ruleForm" :model="formData">
          <div id="invoiceInfo" class="modalBox">
            <div class="infoTitle">
              <div>
                <i class="leftLine"></i>
                <span>{{ $t(`发票信息`) }}</span>
                <span v-if="isDetail">
                  <a style="font-size: 14px; margin-left: 10px" @click="copyOrder">
                    {{ $t(`复制单据`) }}
                  </a>
                </span>
              </div>
            </div>
            <a-row :gutter="16">
              <a-col :span="6">
                <a-form-model-item required :label="$t(`发票号码`)">
                  <a-input
                    :disabled="isDisabled"
                    v-model="formData.invoiceNo"
                    :placeholder="$t(`请输入发票号码`)"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item required :label="$t(`发票代码`)">
                  <a-input
                    :disabled="isDisabled"
                    v-model="formData.invoiceCode"
                    :placeholder="$t(`请输入发票代码`)"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`开票日期`)" required>
                  <DatePicker
                    :needhms="false"
                    style="background-color: #ffffff; width: 100%"
                    :init-date-time="formData.invoiceDate"
                    @choose="chooseOrderDate"
                    :disabled="isDisabled"
                    :placehold="$t(`请选择开票日期`)"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item required :label="$t(`发票类型`)">
                  <SelectInvoiceType :code.sync="formData.invoiceType" :disabled="isDisabled" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item required :label="$t(`发票状态`)">
                  <a-input v-model="invoiceStatusMapping[formData.status]" disabled />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item required :label="$t(`供应商`)">
                  <SelectSupplier
                    :code="formData.sellerCode"
                    :disabled="isDisabled"
                    @supplierIndexChange="supplierIndexChange"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`结算组织`)" required>
                  <CommonQuerySelect
                    :init="true"
                    api="getBusinessUnitList"
                    :disabled="isDisabled"
                    :code.sync="formData.businessUnitCode"
                    :name.sync="formData.businessUnitName"
                    @change="changeBU"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`结算货币`)" required>
                  <SelectCurrency :code.sync="formData.settlementCurrency" :disabled="isDisabled" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`商品金额`)">
                  <a-input v-model="formData.amountWithoutTax" disabled />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`税率`)">
                  <a-input-number
                    :disabled="isDisabled"
                    style="width: 100%"
                    v-model="formData.taxRate"
                    @change="changeTaxRate"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`税额`)">
                  <a-input v-model="formData.taxAmount" disabled />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`总计金额`)">
                  <a-input v-model="formData.amountWithTax" disabled />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`备注`)">
                  <a-input v-model="formData.remark" :disabled="isDisabled" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="16">
              <a-col :span="6">
                <a-form-model-item required :label="$t('发票文件')">
                  <template v-if="formData.url">
                    <img
                      :src="formData.url"
                      alt=""
                      style="width: 100px; height: 100px; object-fit: cover"
                      @click="handlePreview"
                    />
                    <a-icon
                      v-if="!isDisabled"
                      type="delete"
                      class="ml10"
                      style="color: red; cursor: pointer"
                      @click="
                        () => {
                          formData.url = ''
                        }
                      "
                    />
                  </template>
                  <a-upload
                    v-else
                    accept="image/*,.pdf"
                    :disabled="isDisabled"
                    :show-upload-list="false"
                    class="avatar-uploader"
                    :before-upload="beforeUpload"
                    :custom-request="changeUpload"
                  >
                    <a-button :disabled="isDisabled">
                      <a-icon type="upload" />
                      {{ $t('选择文件') }}
                    </a-button>
                  </a-upload>
                </a-form-model-item>
              </a-col>
            </a-row>
          </div>
          <div id="sellerInfo" class="modalBox">
            <div class="infoTitle">
              <div>
                <i class="leftLine"></i>
                {{ $t('开票人信息') }}
              </div>
            </div>
            <a-row :gutter="16">
              <a-col :span="6">
                <a-form-model-item :label="$t(`姓名`)">
                  <a-input
                    :disabled="isDisabled"
                    v-model="formData.sellerName"
                    :placeholder="$t(`请输入姓名`)"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`电话`)">
                  <a-input
                    :disabled="isDisabled"
                    v-model="formData.sellerMobile"
                    :placeholder="$t(`请输入电话`)"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`银行开户行名称`)">
                  <a-input
                    :disabled="isDisabled"
                    v-model="formData.sellerBankName"
                    :placeholder="$t(`请输入银行开户行名称`)"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`银行账号`)">
                  <a-input
                    :disabled="isDisabled"
                    v-model="formData.sellerBankAccount"
                    :placeholder="$t(`请输入银行账号`)"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`纳税人识别号`)">
                  <a-input
                    :disabled="isDisabled"
                    v-model="formData.sellerTaxpayerCode"
                    :placeholder="$t(`请输入纳税人识别号`)"
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </div>
          <div id="buyerInfo" class="modalBox">
            <div class="infoTitle">
              <div>
                <i class="leftLine"></i>
                {{ $t('购买方信息') }}
              </div>
            </div>
            <a-row :gutter="16">
              <a-col :span="6">
                <a-form-model-item :label="$t(`姓名`)">
                  <a-input
                    :disabled="isDisabled"
                    v-model="formData.buyerName"
                    :placeholder="$t(`请输入姓名`)"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`电话`)">
                  <a-input
                    :disabled="isDisabled"
                    v-model="formData.buyerMobile"
                    :placeholder="$t(`请输入电话`)"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`银行开户行名称`)">
                  <a-input
                    :disabled="isDisabled"
                    v-model="formData.buyerBankName"
                    :placeholder="$t(`请输入银行开户行名称`)"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`银行账号`)">
                  <a-input
                    :disabled="isDisabled"
                    v-model="formData.buyerBankAccount"
                    :placeholder="$t(`请输入银行账号`)"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t(`纳税人识别号`)">
                  <a-input
                    :disabled="isDisabled"
                    v-model="formData.buyerTaxpayerCode"
                    :placeholder="$t(`请输入纳税人识别号`)"
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </div>

          <div id="ProductInfo" class="modalBox">
            <div>
              <div class="infoTitle">
                <div>
                  <i class="leftLine"></i>
                  {{ $t('商品信息') }}
                </div>
              </div>
              <template v-if="!isDisabled">
                <div class="pricing-method flex-ct">
                  <a-form-model-item :label="$t(`计价方式`)" required>
                    <a-select
                      :placeholder="$t(`请选择计价方式`)"
                      v-model="calculateTypeNew"
                      style="width: 350px"
                    >
                      <a-select-option value="BY_TOTAL">
                        {{ $t('通过小计计算单价') }}
                      </a-select-option>
                      <a-select-option value="BY_UNIT">
                        {{ $t('通过单价计算小计') }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </div>
                <div style="display: flex; justify-content: space-between">
                  <div style="position: relative; flex: 1">
                    <SearchSku style="width: 350px" @selected="handleChangeSku" />
                    <a-button
                      :disabled="isDisabled"
                      type="primary"
                      style="margin-left: 15px"
                      @click="addGoodsToTable"
                    >
                      <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
                      {{ $t('form.Add') }}
                    </a-button>
                  </div>
                  <div>
                    <div class="flex-ct txt fz16" style="font-weight: bold">
                      <div class="flex-ct mr30">
                        {{ $t('数量合计') }}: {{ purchaseAllQuantity | toThousands }}
                      </div>
                      <div class="flex-ct mr30">
                        <span>{{ $t('总金额合计') }}:</span>
                        <PriceInput
                          :currency="formData.settlementCurrency"
                          :value="purchaseAllAmount"
                          :showTxt="true"
                        />
                      </div>
                      <div class="flex-ct">
                        <span>{{ $t('总价税合计') }}:</span>
                        <PriceInput
                          :currency="formData.settlementCurrency"
                          :value="totalPayableAmount"
                          :showTxt="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="table-wrapper" style="margin-top: 10px">
                <a-table
                  :columns="columns"
                  :dataSource="tableData"
                  bordered
                  size="middle"
                  :loading="tableLoading"
                  :pagination="false"
                  :scroll="{ x: 1500 }"
                  :rowKey="(record, index) => index"
                >
                  <span slot="CommoditySpecification">
                    {{ $t(`form.CommoditySpecification`) }}
                  </span>
                  <span slot="Unit">
                    {{ $t(`form.Unit`) }}
                  </span>
                  <span slot="Remark">
                    {{ $t(`form.Remark`) }}
                  </span>
                  <span slot="Operation">
                    {{ $t(`form.Operation`) }}
                  </span>
                  <span slot="no" slot-scope="text, record, index">
                    {{ index + 1 }}
                  </span>
                  <span slot="productName" slot-scope="text, record">
                    <div v-if="record.invoiceItemName" class="flex-ct">
                      <span>{{ $t('名称') }}:</span>
                      <a-input
                        :disabled="isDisabled"
                        style="width: 180px; flex: 1"
                        v-model="record.invoiceItemName"
                      />
                    </div>
                    <div v-if="record.gtin">{{ $t('代码') }}:{{ record.gtin }}</div>
                  </span>
                  <span slot="unit" slot-scope="text, record">
                    <SelectUnit
                      :disabled="isDisabled"
                      :list="record.unitList"
                      :code.sync="record.unit"
                    />
                  </span>
                  <template slot="productStyle" slot-scope="text, record">
                    <a-input :disabled="isDisabled" v-model="record.invoiceItemStyle" />
                  </template>
                  <template slot="taxRate" slot-scope="text, record, index">
                    <a-input-number
                      :placeholder="$t(`form.PleaseEnter`)"
                      :formatter="Formatter"
                      :parser="Formatter"
                      :value="text"
                      :disabled="!!formData.taxRate"
                      @blur="(e) => handleChange(e.target.value, 'taxRate', index)"
                    />
                  </template>
                  <template slot="taxAmount" slot-scope="text">
                    <PriceInput
                      :currency="formData.settlementCurrency"
                      :value="text"
                      :showTxt="true"
                    />
                  </template>
                  <template slot="amountWithTax" slot-scope="text">
                    <PriceInput
                      :currency="formData.settlementCurrency"
                      :value="text"
                      :showTxt="true"
                    />
                  </template>
                  <template v-for="col in customRenderList" v-slot:[col]="text, record, index">
                    <div :key="col">
                      <!-- includes 用来判断当前的输入类型 -->
                      <div v-if="!isDisabled">
                        <a-input-number
                          :placeholder="$t(`form.PleaseEnter`)"
                          :formatter="Formatter"
                          :parser="Formatter"
                          v-if="col === 'quantity'"
                          :value="text"
                          @blur="(e) => handleChange(e.target.value, col, index)"
                        />
                        <PriceInput
                          v-else-if="col === 'unitPrice'"
                          :placeholder="$t(`form.PleaseEnter`)"
                          :disabled="calculateTypeNew === 'BY_TOTAL'"
                          :value="text"
                          @blur="(value) => handleChange(value, col, index)"
                        />
                        <PriceInput
                          v-else-if="col === 'amountWithoutTax'"
                          :placeholder="$t(`form.PleaseEnter`)"
                          :disabled="calculateTypeNew === 'BY_UNIT'"
                          :value="text"
                          @blur="(value) => handleChange(value, col, index)"
                        />
                        <a-input
                          :placeholder="$t(`form.PleaseEnter`)"
                          v-else-if="inputScopedSlots.includes(col)"
                          :value="text"
                          @change="(e) => handleChange(e.target.value, col, index)"
                        />
                        <a v-else-if="col === 'delect'" @click="onDelect(index)">
                          <a-icon type="delete" style="color: red" />
                        </a>
                        <span v-else>{{ text | toThousands }}</span>
                      </div>
                      <span v-else-if="col === 'quantity'">
                        {{ text }}
                      </span>
                      <span v-else>
                        <PriceInput
                          :currency="formData.settlementCurrency"
                          :value="text"
                          :showTxt="true"
                        />
                      </span>
                    </div>
                  </template>
                </a-table>
              </div>
            </div>
          </div>

          <div id="PayableListInfo" class="modalBox" v-if="showMappingList.length > 0">
            <div class="infoTitle">
              <div>
                <i class="leftLine"></i>
                {{ $t('关联订单信息') }}
              </div>
            </div>
            <div class="table-content">
              <div class="table-body">
                <a-table
                  :columns="tableColumns"
                  :data-source="showMappingList"
                  rowKey="orderSn"
                  :pagination="false"
                >
                  <template slot="amount" slot-scope="text, record">
                    <PriceInput
                      style="width: 300px"
                      :value.sync="record.amount"
                      :currency="record.currency"
                      :disabled="isDisabled"
                    />
                  </template>
                  <template slot="orderSn" slot-scope="text, record">
                    <span class="text-link">{{ text || record.bizOrderSn }}</span>
                  </template>
                  <template slot="delect" slot-scope="text, record">
                    <a @click="onDelect2(record)" v-if="!isDisabled">
                      <a-icon type="delete" style="color: red" />
                    </a>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </a-form-model>
      </section>
    </div>
    <BottomBtns
      :isEdit.sync="isEdit"
      :isDetail="isDetail"
      :draftLoading="draftLoading"
      :saveLoading="saveLoading"
      :status="formData.status"
      @submit="onSubmit"
      @cancel="getInvoiceDetail"
      @back="backForm"
    />

    <a-modal width="80%" :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="formData.url" />
    </a-modal>

    <BindPayableOrder
      :visible.sync="bindOrderVisible"
      :info="formData"
      :supplierCode="formData.sellerCode"
      :supplierName="formData.sellerName"
      :bindList.sync="formData.mappingList"
      @refresh="getInvoiceDetail"
      @goToBottom="scrollToBottom"
    />
  </div>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
import { Modal } from 'ant-design-vue'
import { mapState, mapGetters } from 'vuex'
import DatePicker from '@component/datePicker'
import SelectInvoiceType from '@component/selectInvoiceType'
import SelectSupplier from '@component/selectSupplier'
import SearchSku from '@component/searchSku'
import SelectUnit from '@component/selectUnit'
import SelectCurrency from '@component/selectCurrency'
import BindPayableOrder from '@component/bindPayableOrder'
import BottomBtns from '@component/bottomBtns'
import debounce from '@/common/debounce'
import formatter from '@/common/formatter'
import { add, divide, multiply } from '@/common/number'
import { toFixedUp, convertKeysToCamelCase } from '@/common'
import deepClone from '@/common/deepClone'
export default {
  name: 'InvoiceDetail',
  components: {
    DatePicker,
    SelectSupplier,
    SelectInvoiceType,
    SearchSku,
    SelectUnit,
    BindPayableOrder,
    SelectCurrency,
    BottomBtns,
  },
  data() {
    return {
      id: '',
      isDetail: false,
      isCopy: false,
      isEdit: false,
      formData: {
        mappingList: [],
        businessUnitCode: undefined,
        businessUnitName: undefined,
        functionalCurrency: undefined,
        buyerName: undefined,
        buyerCode: undefined,
        buyerMobile: undefined,
        buyerBankName: undefined,
        buyerBankAccount: undefined,
        buyerTaxpayerCode: undefined,
        sellerName: undefined,
        sellerCode: undefined,
        sellerBankName: undefined,
        sellerBankAccount: undefined,
        sellerMobile: undefined,
        sellerTaxpayerCode: undefined,
        settlementCurrency: 'CNY',
        status: 'DRAFT',
      },
      pageNo: 1,
      pageSize: 20,
      loading: false,
      saveLoading: false,
      draftLoading: false,
      tableData: [],
      tableLoading: false,
      calculateTypeNew: 'BY_TOTAL',
      currentGood: {},
      purchaseAllQuantity: 0,
      purchaseAllAmount: 0,
      totalPayableAmount: 0,
      customRenderList: ['quantity', 'unitPrice', 'currency', 'delect', 'amountWithoutTax'],
      // 用来匹配插槽中显示input框
      inputScopedSlots: ['quantity', 'unitPrice', 'amountWithoutTax'],
      fileInfo: {},
      previewVisible: false,
      bindOrderVisible: false,
    }
  },
  provide() {
    return {
      page: 'invoiceDetail',
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapState(['currencyMap']),
    ...mapGetters(['invoiceStatusMapping']),
    scrollHeight() {
      let height = window.innerHeight - 300
      return height * this.$store.state.rate + 'px'
    },
    columns() {
      let list = [
        {
          title: 'NO',
          align: 'center',
          key: 'NO',
          width: 60,
          scopedSlots: {
            customRender: 'no',
          },
        },
        {
          title: this.$t('商品信息'),
          dataIndex: 'productName',
          width: 200,
          scopedSlots: {
            customRender: 'productName',
          },
        },
        {
          slots: { title: 'CommoditySpecification' },
          align: 'center',
          dataIndex: 'productStyle',
          width: 120,
          scopedSlots: {
            customRender: 'productStyle',
          },
        },
        {
          align: 'center',
          dataIndex: 'unit',
          width: 100,
          title: this.$t('单位'),
          scopedSlots: {
            customRender: 'unit',
          },
        },
        {
          title: this.$t('数量'),
          align: 'center',
          dataIndex: 'quantity',
          width: 120,
          scopedSlots: {
            customRender: 'quantity',
          },
        },
        {
          title: this.$t('单价'),
          align: 'center',
          dataIndex: 'unitPrice',
          width: 120,
          scopedSlots: {
            customRender: 'unitPrice',
          },
        },
        {
          title: this.$t('税率') + '(%)',
          align: 'center',
          dataIndex: 'taxRate',
          width: 120,
          scopedSlots: {
            customRender: 'taxRate',
          },
        },
        {
          title: this.$t('税额'),
          align: 'center',
          dataIndex: 'taxAmount',
          width: 120,
          scopedSlots: {
            customRender: 'taxAmount',
          },
        },
        {
          title: this.$t('金额'),
          align: 'center',
          dataIndex: 'amountWithoutTax',
          width: 120,
          scopedSlots: {
            customRender: 'amountWithoutTax',
          },
        },
        {
          title: this.$t('价税总计'),
          align: 'center',
          dataIndex: 'amountWithTax',
          width: 120,
          scopedSlots: {
            customRender: 'amountWithTax',
          },
        },
        {
          slots: { title: 'Operation' },
          align: 'center',
          dataIndex: 'delect',
          width: 120,
          scopedSlots: {
            customRender: 'delect',
          },
        },
      ]
      return list
    },
    tableColumns() {
      let list = [
        {
          dataIndex: 'orderSn',
          title: this.$t('应付单号'),
          scopedSlots: {
            customRender: 'orderSn',
          },
        },
        {
          dataIndex: 'amount',
          title: this.$t('金额'),
          scopedSlots: {
            customRender: 'amount',
          },
        },
        {
          title: this.$t('操作'),
          dataIndex: 'delect',
          width: 120,
          scopedSlots: {
            customRender: 'delect',
          },
        },
      ]
      if (this.isDisabled) {
        list.splice(-1, 1)
      }
      return list
    },
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    showMappingList() {
      return this.formData.mappingList?.filter((item) => item.status !== -1) || []
    },
  },
  methods: {
    Formatter: formatter,
    init() {
      this.isDetail = false
      this.isCopy = false
      let invoiceFormdata = localStorage.getItem('invoiceFormdata'),
        payableOrderPushdata = localStorage.getItem('payableOrderPushdata') //应付单下推生成的
      if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.isDetail = true
        this.getInvoiceDetail()
      } else {
        this.reset()
      }
      if (invoiceFormdata) {
        this.isCopy = true
        let data = JSON.parse(invoiceFormdata)
        console.log('invoiceFormdata: ', data)
        delete data.mappingList
        delete data.id
        this.formData = data
        this.formData.status = 'DRAFT'
        this.tableData = deepClone(this.formData.tableData)
        delete this.formData.tableData
        localStorage.removeItem('invoiceFormdata')
        this.$nextTick(() => {
          this.calcAllAmount()
        })
      } else if (payableOrderPushdata) {
        this.isCopy = true
        let data = JSON.parse(payableOrderPushdata)
        console.log('payableOrderPushdata: ', data)
        delete data.id
        this.formData.status = 'DRAFT'
        this.tableData = deepClone(data).map((item) => {
          let obj = {
            ...item,
            invoiceItemName: item.itemName,
            invoiceItemStyle: item.itemStyle,
            amountWithTax: item.payableAmount,
          }
          if (typeof item.unitList === 'string') {
            obj.unitList = JSON.parse(item.unitList)
          }
          return obj
        })
        localStorage.removeItem('payableOrderPushdata')
        this.$nextTick(() => {
          this.calcAllAmount()
        })
      }

      // if (this.$route.query.orderSn) {
      //   this.setMappingList(this.$route.query.orderSn)
      // }
    },
    changeBU(value) {
      this.formData.businessUnitCode = value.code
      this.formData.businessUnitName = value.name
      this.formData.functionalCurrency = value.currency

      this.formData.buyerName = value.name
      this.formData.buyerCode = value.code
      this.formData.buyerMobile = value.mobile
      this.formData.buyerBankName = value.bank_name
      this.formData.buyerBankAccount = value.bank_account
      this.formData.buyerTaxpayerCode = value.taxpayer_identification_no
    },
    getInvoiceDetail() {
      http({
        url: api.getInvoiceDetail,
        data: {
          invoiceId: this.id,
        },
        hasLoading: true,
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.formData = result
          this.tableData = result.invoiceDetailList
          this.$nextTick(() => {
            this.calcAllAmount()
          })
        },
      })
    },
    chooseOrderDate(data) {
      this.formData.invoiceDate = data.data
    },
    backForm(back = 0) {
      if (back) {
        this.$router.go(-1)
        return
      }
      this.$router.push({ name: 'invoiceList' })
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    supplierIndexChange(val) {
      this.formData.sellerName = val.supplierName
      this.formData.sellerCode = val.supplierCode
      this.formData.sellerBankName = val.bankName
      this.formData.sellerBankAccount = val.bankAccount
      this.formData.sellerMobile = val.mobile
      this.formData.sellerTaxpayerCode = val.taxpayer_identification_no
    },
    handleChange(value, colName, index) {
      const newData = [...this.tableData]
      const target = newData[index]
      if (target) {
        target[colName] = value
        this.calcAmount()
        this.tableData = newData
      }
    },
    calcAmount() {
      this.getTheAmountInPcFn()
      this.calcAllAmount()
    },
    calcAllAmount() {
      let quantity = 0,
        amount = 0,
        amountWithTax = 0
      this.tableData.forEach((item) => {
        quantity = add(quantity, item.quantity)
        amount = add(amount, item.amountWithoutTax)
        amountWithTax = add(amountWithTax, item.amountWithTax)
      })
      this.purchaseAllQuantity = quantity
      this.purchaseAllAmount = amount
      this.totalPayableAmount = amountWithTax
    },
    onDelect(index) {
      this.tableData.splice(index, 1)
      this.$nextTick(() => {
        this.calcAmount()
      })
    },
    onDelect2(record) {
      record.amount = 0
      this.$set(record, 'status', -1)
    },
    handleChangeSku(data) {
      let itemStyle = data.skuStyleList.map((item) => item.styleValue).join(';')
      this.currentGood = {
        amountWithTax: '',
        amountWithoutTax: '',
        baseUnit: '',
        discountAmount: '',
        discountRate: '',
        gtin: data.barcode,
        itemName: data.skuName,
        invoiceItemName: data.skuName,
        itemStyle: itemStyle,
        invoiceItemStyle: itemStyle,
        quantity: '',
        unit: '',
        taxAmount: '',
        taxRate: this.formData.taxRate,
        unitPrice: '',
        unitPriceWithTax: '',
        unitList: data.unitList,
      }
    },
    addGoodsToTable() {
      if (this.currentGood.itemName) {
        let procurementUnitItem =
            this.currentGood.unitList?.find((item) => item.procurementUnit) || {},
          baseUnitItem = this.currentGood.unitList?.find((item) => item.baseUnit) || {}
        this.currentGood.unit = procurementUnitItem.unitNameFull || baseUnitItem.unitNameFull
        if (
          this.tableData.length &&
          this.tableData.findIndex((i) => i.gtin == this.currentGood.gtin) !== -1
        ) {
          return this.$message.error(this.$t('form.DoNotAddRepeatedly'))
        }
        this.tableData.push(this.currentGood)
        this.scrollToBottom()
      }
    },
    // 以pc获取金额
    getTheAmountInPcFn() {
      if (!this.tableData.length) {
        this.formData.amountWithoutTax = 0
        this.formData.taxAmount = 0
        this.formData.amountWithTax = 0
        return
      }
      let hasEmpty = false
      let sum = 0,
        taxTotal = 0
      this.tableData.forEach((item) => {
        if (item.quantity && (item.unitPrice || item.amountWithoutTax)) {
          //修改单价和数量 小计跟着改
          if (this.calculateTypeNew === 'BY_TOTAL') {
            item.unitPrice = toFixedUp(divide(item.amountWithoutTax, item.quantity), 4)
          } else {
            item.amountWithoutTax = multiply(item.unitPrice, item.quantity).toFixed(4)
          }
          sum += item.amountWithoutTax * 1
        } else {
          hasEmpty = true
        }
        if (item.amountWithoutTax && item.taxRate) {
          let taxAmount = Number(((item.amountWithoutTax * item.taxRate) / 100).toFixed(4))
          this.$set(item, 'taxAmount', taxAmount)
          taxTotal += taxAmount
          item.amountWithTax = +add(item.amountWithoutTax, taxAmount).toFixed(4)
        }
      })
      if (hasEmpty) return

      this.formData.amountWithoutTax = +sum.toFixed(4)
      this.formData.taxAmount = taxTotal
      this.setTotalAmount()
    },
    setTotalAmount() {
      let result = this.formData.amountWithoutTax * 1 + this.formData.taxAmount * 1
      this.formData.amountWithTax = isNaN(result) ? '' : +result.toFixed(4)
    },
    scrollToBottom() {
      this.$nextTick(() => {
        document.querySelector('.fixContent').scrollTo(0, 2000)
      })
    },
    changeTaxRate: debounce(function (value) {
      this.$nextTick(() => {
        this.tableData.forEach((item) => {
          this.$set(item, 'taxRate', value)
        })
        this.calcAmount()
      })
    }),
    beforeUpload(file) {
      this.fileInfo = file
    },
    changeUpload() {
      http({
        url: api.filePreUpload,
        data: {
          file_name: this.fileInfo.name,
        },
        success: (res) => {
          fetch(res.result.upload_url, {
            method: 'PUT',
            body: this.fileInfo,
          })
          this.$set(this.formData, 'url', res.result.url)
          console.log('this.formData: ', this.formData)
        },
      })
    },
    onSubmit: debounce(async function (status) {
      const list = this.tableData.map((item) => ({
        amountWithTax: item.amountWithTax,
        amountWithoutTax: item.amountWithoutTax,
        gtin: item.gtin,
        invoiceItemName: item.invoiceItemName,
        invoiceItemStyle: item.invoiceItemStyle,
        itemName: item.itemName,
        itemStyle: item.itemStyle,
        quantity: item.quantity,
        taxAmount: item.taxAmount,
        taxRate: item.taxRate,
        unit: item.unit,
        unitPrice: item.unitPrice,
      }))
      const data = {
        ...deepClone(this.formData),
        merchantCode: this.$store.state.userSession.merchantCode,
        invoiceItemList: list,
      }
      delete data.mappingList
      if (this.id) {
        data.id = this.id
      }
      data.status = status
      console.log('data: ', data)
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      await http({
        url: api.createOrUpdateInvoice,
        data,
        success: async (res) => {
          this.saveLoading = false
          this.draftLoading = false
          if (res.success) {
            if (this.$route.query.id) {
              await this.modifyBindList()
              this.$message.success(this.$t('layer.SavingSucceeded'))
              this.init()
            } else if (status === 'WAIT_CHECK') {
              if (this.formData.mappingList?.length > 0) {
                await this.bindHandle(res.result.invoice_id)
              }
              this.$success({
                title: this.$t(`layer.Success`),
                content: this.$t(`layer.CreateSuccess`),
              })
              if (this.isCopy) {
                this.backForm()
              } else {
                this.init()
              }
            } else {
              this.$message.success({
                title: this.$t(`layer.Success`),
                content: this.$t(`暂存成功`),
              })
            }
          }
        },
        fail: (err) => {
          this.saveLoading = false
          this.draftLoading = false
          this.$message.error(err.msg)
        },
      })
    }),
    async bindHandle(invoiceId) {
      await http({
        url: api.bindInvoicePayable,
        data: {
          invoiceId,
          mappingList: this.formData.mappingList,
        },
      })
    },
    reset() {
      Object.assign(this.$data, this.$options.data())
    },
    cancelEdit() {
      this.isEdit = false
      this.getInvoiceDetail()
    },
    handlePreview() {
      this.previewVisible = true
    },
    bindOrderHandle() {
      this.bindOrderVisible = true
    },
    goToPay() {
      Modal.confirm({
        title: this.$t('是否确认支付?'),
        onOk: () => {
          http({
            url: api.payInvoice,
            data: {
              invoiceId: this.formData.id,
            },
            success: () => {
              this.$message.success(this.$t('支付成功'))
              this.getInvoiceDetail()
            },
          })
        },
      })
    },
    cancelPay() {
      Modal.confirm({
        title: this.$t('是否确认取消支付?'),
        onOk: () => {
          http({
            url: api.rejectPayInvoice,
            data: {
              invoiceId: this.formData.id,
            },
            success: () => {
              this.$message.success(this.$t('取消支付成功'))
              this.getInvoiceDetail()
            },
          })
        },
      })
    },
    copyOrder() {
      this.formData.tableData = this.tableData
      let data = JSON.stringify(this.formData)
      localStorage.setItem('invoiceFormdata', data)
      this.$router.push('/invoiceManage/invoiceDetail')
    },
    async modifyBindList() {
      let mappingList = this.formData.mappingList.map((item) => ({
        amount: item.amount,
        orderId: item.id,
        orderSn: item.orderSn,
      }))
      await http({
        url: api.modifyInvoiceAmount,
        data: {
          invoiceId: this.formData.id,
          mappingList,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 0;
}
.infoTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fixContent {
  padding-top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 16px 0;
}
.content-overflow {
  width: 100%;
  overflow: auto;
  display: flex;
}
.left-div {
  flex: 1;
}
.modalBox {
  margin-bottom: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}
.ant-upload ::v-deep img {
  width: 100px;
  height: 100px;
}
</style>
