<template>
  <a-modal
    width="800px"
    :dialog-style="{ top: '20px' }"
    :title="$t('关联应付单')"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @cancel="cancelModal"
    @ok="bindHandle"
  >
    <div class="table-content">
      <div class="table-header flex-ct mb10">
        <a-input
          allow-clear
          v-model.trim="orderSn"
          :placeholder="$t('请输入应付单号')"
          style="width: 350px"
          class="mr10"
        />
        <a-input
          v-if="supplierName"
          class="mr10"
          disabled
          :value="supplierName"
          style="width: 250px"
        />
        <a-button type="primary" @click="queryOrderList(true)">
          {{ $t('查询') }}
        </a-button>
      </div>
      <div class="table-body">
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :scroll="{
            y: 500,
          }"
          :columns="tableColumns"
          :data-source="tableData"
          rowKey="id"
          :pagination="false"
          :loading="tableLoading"
        >
          <template slot="invoiced_amount" slot-scope="text, record">
            <PriceInput :showTxt="true" :value="text" :currency="record.settlement_currency" />
          </template>
          <template slot="use_amount" slot-scope="text, record">
            <PriceInput :showTxt="true" :value="text" :currency="record.settlement_currency" />
          </template>
          <template slot="bind_amount" slot-scope="text, record">
            <PriceInput
              :value.sync="record.bind_amount"
              :currency="record.settlement_currency"
              @blur="
                (val) => {
                  blurHandle(val, record)
                }
              "
            />
          </template>
        </a-table>
      </div>
      <div class="table-footer" style="text-align: right">
        <pagination
          :pageNo="pageNo"
          :length="pageSize"
          :total-count="total"
          @paginate="paginate"
        ></pagination>
      </div>
    </div>
    <div class="amount-content flex-ct">
      <div />
      <div class="amount-item" v-if="info.invoiceNo">
        {{ $t('发票号码') }}: {{ info.invoiceNo }}
      </div>
      <div class="amount-item" v-if="info.amountWithTax">
        <span>{{ $t('发票金额') }}:</span>
        <span class="amount">{{ info.amountWithTax }}</span>
      </div>
      <div class="amount-item">
        <span>{{ $t('关联应付金额') }}:</span>
        <span class="amount" :class="isCorrect ? 'text-success' : 'text-danger'">
          {{ bindAmount }}
        </span>
      </div>
    </div>
  </a-modal>
</template>

<script>
import pagination from '@component/pagination'
import http from '@/service/axios'
import api from '@/service/api'
import { spaceToComma } from '@/common'
import { add } from '@/common/number'
export default {
  name: 'bindPayableOrder',
  components: { pagination },
  props: {
    visible: {
      type: Boolean,
    },
    info: {
      type: Object,
    },
    supplierCode: {},
    supplierName: {},
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.queryOrderList(true)
        }
      },
    },
  },
  data() {
    return {
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      tableLoading: false,
      selectedRowKeys: [],
      selectedRows: [],
      orderSn: undefined,
      confirmLoading: false,
    }
  },
  computed: {
    tableColumns() {
      let list = [
        {
          dataIndex: 'order_sn',
          title: this.$t('应付单号'),
        },
        {
          dataIndex: 'invoiced_amount',
          title: this.$t('已绑金额'),
          scopedSlots: { customRender: 'invoiced_amount' },
        },
        {
          dataIndex: 'use_amount',
          title: this.$t('可绑金额'),
          scopedSlots: { customRender: 'use_amount' },
        },
        {
          dataIndex: 'bind_amount',
          title: this.$t('关联金额'),
          scopedSlots: { customRender: 'bind_amount' },
        },
      ]
      return list
    },
    bindAmount() {
      let amount = this.selectedRows.reduce((total, item) => {
        total = add(total, +item.bind_amount)
        return Number(total.toFixed(4))
      }, 0)
      return amount
    },
    isCorrect() {
      return +this.bindAmount === +this.info.amountWithTax
    },
  },
  methods: {
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.queryOrderList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    queryOrderList(first_page = false) {
      first_page && (this.pageNo = 1)
      this.$nextTick(async () => {
        let data = {
          page_no: this.pageNo,
          page_size: this.pageSize,
          invoice_status: 'WAIT_INVOICE',
          supplierCode: this.supplierCode,
        }
        let orderSn = spaceToComma(this.orderSn)
        if (orderSn.includes(',')) {
          data.orderSnList = orderSn.split(',')
        } else if (orderSn) {
          data.orderSn = orderSn
        }
        this.tableLoading = true
        let res = await http({
          instance: this,
          type: 'post',
          url: api.getPayableList,
          data,
        })
        let purchaseOrderList = [...(res.result.list || '')]
        this.tableData = purchaseOrderList.map((item) => ({
          ...item,
          bind_amount: 0,
          use_amount: item.payable_amount - (item.invoiced_amount || 0),
        }))
        this.total = res.result.total
        this.tableLoading = false
      })
    },
    cancelModal() {
      this.$emit('update:visible', false)
    },
    bindHandle() {
      if (this.selectedRows.length === 0) {
        this.$message.warning(this.$t('请选择关联订单'))
        return
      }
      this.confirmLoading = true
      let mappingList = this.selectedRows.map((item) => ({
        amount: item.bind_amount,
        orderId: item.id,
        orderSn: item.order_sn,
        currency: item.settlement_currency,
      }))
      if (this.info.id) {
        http({
          url: api.bindInvoicePayable,
          data: {
            invoiceId: this.info.id,
            mappingList,
          },
          success: (res) => {
            this.confirmLoading = false
            if (res.success) {
              this.$message.success(this.$t('关联成功'))
              this.cancelModal()
              this.$emit('refresh')
            }
          },
          fail: (err) => {
            this.$message.error(err.msg || err.message || this.$t('关联失败'))
            this.confirmLoading = false
          },
        })
      } else {
        this.confirmLoading = false
        this.$emit('update:bindList', mappingList)
        this.cancelModal()
        this.$emit('goToBottom')
      }
    },
    blurHandle(val, record) {
      if (val && !this.selectedRowKeys.includes(record.id)) {
        this.selectedRowKeys.push(record.id)
        this.selectedRows.push(record)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.amount-content {
  border-top: 1px solid #999;
  padding-top: 10px;
  justify-content: flex-end;
  .amount-item {
    margin-left: 20px;
    display: flex;
    align-items: center;
    height: 32px;
  }
  .amount {
    font-size: 20px;
    font-weight: 600;
    margin-left: 4px;
  }
}
</style>
